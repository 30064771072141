.toast-stack {
  position: fixed;
  min-height: 200px;
  z-index: 1100;
  // width: 100%;
  top: 10px;
  right: 10px;
}
.toast {
  min-width: 250px;
  color: #fff;
  &.error {
    background: #d61414fa;
  }
  &.success {
    background: green;
  }
  .close {
    cursor: pointer;
  }
}
